import { IStoreApiItem, StoreApiItem } from '@/common/interfaces/store-api-item.interface'
import { IStoreApiList, StoreApiList } from '@/common/interfaces/store-api-list.interface'

import {
  IBypassStatus,
  IDoctorAccess,
  IDoctorBasic,
  IDoctorBillingHistory,
  IDoctorDetails,
  IDoctorDocument,
  IDoctorDocumentsAudit,
  IDoctorDocumentUpdate,
  IDoctorPreferences,
  IDoctors,
  IEmployee,
  IEmployeeDetails,
  IEmployees,
  IPatients,
  IPaymentHistory,
  IPermissions,
  IPermissionsUpdate,
  IStaff,
  IStaffDetails,
  IStaffs,
} from 'src/features/users/interfaces/users.interface'

export interface IUsersState {
  doctors: IStoreApiList<IDoctors>
  doctorsAll: IStoreApiList<IDoctorBasic[]>
  doctor: IStoreApiItem<IDoctorDetails>
  doctorDelete: IStoreApiItem<IDoctorDetails>
  doctorCreate: IStoreApiItem<IDoctorDetails>
  doctorUpdate: IStoreApiItem<IDoctorDetails>
  doctorDocuments: IStoreApiList<IDoctorDocument[]>
  doctorDocumentUpdate: IStoreApiList<IDoctorDocumentUpdate[]>
  doctorDocumentsAudit: IStoreApiList<IDoctorDocumentsAudit[]>
  doctorBillingHistory: IStoreApiList<IDoctorBillingHistory[]>
  doctorPayments: IStoreApiList<IPaymentHistory[]>
  doctorAccess: IStoreApiList<IDoctorAccess[]>
  doctorsPatients: IStoreApiList<IPatients>
  doctorBypass: IStoreApiItem<IBypassStatus>
  doctorPreferences: IStoreApiItem<IDoctorPreferences>

  staff: IStoreApiList<IStaffs>
  staffUpdate: IStoreApiList<IStaff>
  staffCreate: IStoreApiList<IStaff>
  staffDetails: IStoreApiList<IStaffDetails>
  employees: IStoreApiList<IEmployees>
  employee: IStoreApiItem<IEmployeeDetails>
  employeeUpdate: IStoreApiItem<IEmployee>
  employeeCreate: IStoreApiItem<IEmployee>

  permissions: IStoreApiItem<IPermissions>
  permissionsUpdate: IStoreApiItem<IPermissionsUpdate[]>
}

export class UsersState implements IUsersState {
  doctors: IStoreApiList<IDoctors> = new StoreApiList()
  doctorsAll: IStoreApiList<IDoctorBasic[]> = new StoreApiList()
  doctor: IStoreApiItem<IDoctorDetails> = new StoreApiItem()
  doctorDelete: IStoreApiItem<IDoctorDetails> = new StoreApiItem()
  doctorCreate: IStoreApiItem<IDoctorDetails> = new StoreApiItem()
  doctorUpdate: IStoreApiItem<IDoctorDetails> = new StoreApiItem()
  doctorBillingHistory: IStoreApiList<IDoctorBillingHistory[]> = new StoreApiList()
  doctorPayments: IStoreApiList<IPaymentHistory[]> = new StoreApiList()
  doctorsPatients: IStoreApiList<IPatients> = new StoreApiList()

  doctorDocuments: IStoreApiList<IDoctorDocument[]> = new StoreApiList()
  doctorDocumentUpdate: IStoreApiList<IDoctorDocumentUpdate[]> = new StoreApiItem()
  doctorDocumentsAudit: IStoreApiList<IDoctorDocumentsAudit[]> = new StoreApiItem()
  doctorAccess: IStoreApiList<IDoctorAccess[]> = new StoreApiItem()
  doctorBypass: IStoreApiItem<IBypassStatus> = new StoreApiItem()
  doctorPreferences: IStoreApiItem<IDoctorPreferences> = new StoreApiItem()

  staff: IStoreApiList<IStaffs> = new StoreApiList()
  staffCreate: IStoreApiList<IStaff> = new StoreApiItem()
  staffUpdate: IStoreApiList<IStaff> = new StoreApiItem()
  staffDetails: IStoreApiList<IStaffDetails> = new StoreApiItem()

  employees: IStoreApiList<IEmployees> = new StoreApiList()
  employee: IStoreApiItem<IEmployeeDetails> = new StoreApiItem()
  employeeUpdate: IStoreApiItem<IEmployee> = new StoreApiItem()
  employeeCreate: IStoreApiItem<IEmployee> = new StoreApiItem()

  permissions: IStoreApiItem<IPermissions> = new StoreApiList()
  permissionsUpdate: IStoreApiItem<IPermissionsUpdate[]> = new StoreApiItem()
}
